<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="邀请码" prop="inviteCode">-->
<!--                <a-input v-model="queryParam.inviteCode" placeholder="请输入邀请码" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->



            <a-col :md="5" :sm="24">
              <a-form-item label="手机号" prop="userPhone">
                <a-input v-model="queryParam.userPhone" placeholder="请输入手机号" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="5" :sm="24">
              <a-form-item label="真实姓名" prop="realName">
                <a-input v-model="queryParam.realName" placeholder="请输入真实姓名" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="邮箱" prop="userEmail">
                <a-input v-model="queryParam.userEmail" placeholder="请输入邮箱" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <template v-if="advanced">-->

              <a-col :md="5" :sm="24">
                <a-form-item label="用户名" prop="inviteCode">
                  <a-input v-model="queryParam.nickname" placeholder="请输入用户名" allow-clear/>
                </a-form-item>
              </a-col>
            <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
            <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

<!--            </template>-->
<!--            <a-col :md="!advanced && 8 || 24" :sm="24">-->
<!--&lt;!&ndash;              <span class="table-page-search-submitButtons" :style="advanced && {  overflow: 'hidden' } || {} ">&ndash;&gt;-->
<!--                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>-->
<!--                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>-->
<!--&lt;!&ndash;                <a @click="toggleAdvanced" style="margin-left: 8px">&ndash;&gt;-->
<!--&lt;!&ndash;                  {{ advanced ? '收起' : '展开' }}&ndash;&gt;-->
<!--&lt;!&ndash;                  <a-icon :type="advanced ? 'up' : 'down'"/>&ndash;&gt;-->
<!--&lt;!&ndash;                </a>&ndash;&gt;-->
<!--&lt;!&ndash;              </span>&ndash;&gt;-->
<!--            </a-col>-->
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:distributor:add']">
          <a-icon type="plus" />新增
        </a-button>
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:distributor:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:distributor:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
        <a-button type="primary" @click="handleExport">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
          :loading="loading"
          :size="tableSize"
          rowKey="id"
          :scroll="{x:'1750px'}"
          :columns="columns"
          :data-source="list"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :pagination="false"
          :bordered="tableBordered"
          :rowKey="(record, index) => index + 1"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

        <span slot="status" slot-scope="text, record">
          <a-switch checkedChildren="启用" unCheckedChildren="封禁" v-model="record.status == 0" @change="changeIsOpen($event,record)"/>
        </span>

        <span slot="inviteUrl" slot-scope="text, record">
          <a @click="copyUrl(record.inviteUrl)">{{record.inviteUrl}}</a>
        </span>


        <span slot="operation" slot-scope="text, record">
          <a v-if="record.distributorId" @click="$refs.createForm.editData(record)">
            <a-icon type="detail" />编辑
          </a>
          <a v-if="!record.distributorId" @click="$refs.createForm.handleAddUser(record.id)">
            <a-icon type="detail" />设置二级分销商
          </a>
<!--          <a-divider type="vertical"  />-->
<!--          <a @click="$refs.SecondDistributorIndex.getDatas(record.id)" >-->
<!--            <a-icon type="edit" />下级用户充值明细-->
<!--          </a>-->
          <a-divider type="vertical"  />
           <a @click="$refs.SecondDistributorUserIndex.getSecondDatas(record.id)" >
            <a-icon type="search" />下级用户列表
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"

      />
      <second-distributor-index ref="SecondDistributorIndex"></second-distributor-index>
      <second-distributor-user-index ref="SecondDistributorUserIndex"></second-distributor-user-index>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageDistributors,changeStatus, delDistributor } from '@/api/user/distributor'
import CreateForm from '../modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import auth from "@/plugins/auth";
import SecondDistributorIndex from "@/views/distributorData/second/SecondDistributorIndex";
import SecondDistributorUserIndex from "@/views/distributorData/second/SecondDistributorUserIndex";


export default {
  name: 'SecondDistributor',
  components: {
    SecondDistributorUserIndex,
    SecondDistributorIndex,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        inviteUrl: null,
        inviteCode: null,
        userEmail: null,
        userPhone: null,
        distributorRait: null,
        bankName: null,
        bankNo: null,
        realName: null,
        bankAddress: null,
        status: null,
        address: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          width: 80,
          customRender: (text, record, index) => index + 1,
          align: 'center'
        },
        // {
        //   title: '用户ID',
        //   dataIndex: 'id',
        //   // ellipsis: true,,
        //   width: '30%',
        //   align: 'center'
        // },
        {
          title: '用户名',
          dataIndex: 'nickname',
          width: 120,
          align: 'center'
        },
        // {
        //   title: '邮箱',
        //   dataIndex: 'userEmail',
        //   // ellipsis: true,,
        //   width: '30%',
        //   align: 'center'
        // },
        // {
        //   title: '状态',
        //   dataIndex: 'status',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'status' },
        //   align: 'center'
        // },

        {
          title: '手机号',
          dataIndex: 'userPhone',
          width: 120,
          align: 'center'
        },
        {
          title: '邮箱',
          dataIndex: 'userEmail',
          width: 180,
          align: 'center'
        },
        {
          title: '真实姓名',
          dataIndex: 'realName',
          width: 120,
          align: 'center'
        },
        // {
        //   title: '手机号',
        //   dataIndex: 'userPhone',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '是否二级分销商',
          dataIndex: 'isDistributor',
          width: 120,
          align: 'center',
          customRender: function (v, r, index) {
                if (v == 0) {
                  return "否";
                } else if (v == 1) {
                  return "是";
                }
              }
        },
        {
          title: '邀请链接(点击复制)',
          dataIndex: 'inviteUrl',
          scopedSlots: { customRender: 'inviteUrl' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '赠送钻石数',
        //   dataIndex: 'giveDiamond',
        //   // ellipsis: true,,
        //   width: '20%',
        //   align: 'center'
        // },
        {
          title: '邀请码',
          dataIndex: 'invitationCode',
          width: 120,
          align: 'center'
        },

        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 180,
          align: 'center'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询分销商列表 */
    getList () {
      this.loading = true
      pageDistributors(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    copyUrl(url) {


      var textarea = document.createElement('textarea');
      textarea.style.position = 'fixed';
      textarea.style.opacity = 0;
      textarea.value = url;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$message.success(
          '复制成功'
      )


    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },

    changeIsOpen (e,record) {
      record.status = e ? 0 : 1
      changeStatus(record).then(response => {
        this.$message.success(
            '操作成功'
        )
        return  record.status = e ? 0 : 1
      })
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        inviteUrl: undefined,
        inviteCode: undefined,
        userEmail: undefined,
        userPhone: undefined,
        distributorRait: undefined,
        bankName: undefined,
        bankNo: undefined,
        realName: undefined,
        bankAddress: undefined,
        nickname: undefined,
        status: undefined,
        address: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.queryParam.pageNum = 1
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        //content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delDistributor(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user-distributors/exportSecond', {
            ...that.queryParam
          }, `分销商_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
